const ENDPOINTS = {
  PROTOCOL: 'https://',

  WSS_CRYPTO_PRICE: `:wsAdrr:/ws/crypto/:pair:/:account:/`,

  // REST_API
  LOGIN: '/authx/jwt/create/',
  REFRESH: '/authx/jwt/refresh/',
  VERIFY: '/authx/jwt/verify/',
  API_AUTH_USERS_RESET_PASSWORD: '/authx/users/reset_password/',
  API_AUTH_RESET_PASSWORD_CONFIRM: '/authx/users/reset_password_confirm/',

  // AUTHX_URL
  AUTH_USER_ME: '/authx/users/me/',
  AUTH_SET_PASSWORD: '/authx/users/set_password/',
  AUTH_USER: '/authx/users/',
  AUTH_RESET_PASSWORD: '/authx/users/reset_password/',
  AUTH_RESET_PASSWORD_CONFIRM: '/authx/users/reset_password_confirm/',
  AUTH_USER_SESSION: '/authx/users-session/:id:',
  AUTH_USER_ACCOUNTS: '/authx/users-account/',
  AUTH_USER_TENANTS: '/authx/user/tenants/',

  // CORE_URL
  AUTH_ACTIVATE_ACCOUNT: '/core/activate/',
  NEW_CLINET: '/core/new_clients/',
  ACCOUNTS: '/core/accounts/',
  ACCOUNTS_SINGLE: '/core/accounts/:id:/',
  ACCOUNTS_CATEGORY: '/core/company-types/',
  CURRIENCES: '/core/currencies/',
  PRODUCT_TYPE: '/core/product-types/',
  CONTACT_FORM: '/core/send-contact-form/',
  WAIT_FORM: '/core/send-wait-data/',
  COMPANY_FORM: '/core/send-company-data/',
  NIP_SERACH: '/core/search_company/',

  // COMMON_URL
  EMIAL_LOG: '/common/email-log/',
  EMIAL_LOG_SINGLE: '/common/email-log/:id:/',

  // CUSTOMERS_URL
  CUSTOMERS: '/customer/customers/',
  CUSTOMERS_SINGLE: '/customer/customers/:id:/',
  ADDRESSES: '/customer/addresses/',
  ADDRESSES_SINGLE: '/customer/addresses/:id:/',

  // PRODUCTS_URL
  PRODUCTS: '/products/products/',
  PRODUCTS_SINGLE: '/products/products/:id:/',
  CATEGORIES: '/products/categories/',
  CATEGORIES_SINGLE: '/products/categories/:id:/',

  // PRODUCTS_URL
  BASKET_DECODE: '/core/decode-basket/',
  BASKETS: '/payments/baskets/',
  BASKETS_SINGLE: '/payments/baskets/:id:/',
  BASKETS_DELETE_BULK: '/payments/baskets/delete-multiple/',

  // PAYMENTS_URL
  CREATE_PAYMENTS: '/shared/payments/',
  CREATEA_PAYMENTS_TOKEN: '/shared/generate-paywall-token/',
  DELETE_PAYMENTS_TOKEN: '/shared/delete-paywall-token/',
  GET_PAYMENTS_TOKEN: '/shared/retrieve-partial-token/',
  VERIFY_CHECKSUM: '/shared/verify-checksum/',
  VERIFY_PAYMENT: '/shared/payment-status/',
  GENERATE_CHECKSUM: '/shared/generate-checksum/',
  AUTH_PAYMENTS: '/shared/payments/:id:/authorization_flow/',
  SELECT_PROVDER: '/shared/payments/:id:/authorization-flow/actions/provider-selection/',

  PAYMENTS_LIST: '/payments/payments/',
  PAYMENTS_SINGLE: '/payments/payments/:id:/',

  PAYMENTS_CUSTOMERS: '/payments/customer_payments/',

  PAYMENTS_PAY_LOGS: '/payments/logs-payments/',

  // APPLICATIONS_URL
  GET_APPLICATIONS: '/o/applications/retrieve_application/',
  CREATE_APPLICATIONS: '/o/applications/create_application/',
  DELETE_APPLICATIONS: '/o/applications/delete_application/',
};

export default ENDPOINTS;
