import type { PaletteColor } from '@mui/material';
import type { NeutralColors } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
  };
};

export const neutral: NeutralColors = {
  50: '#FFFAF4',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#9fadac',
  400: '#889998',
  500: '#708483',
  600: '#58706e',
  700: '#405b5a',
  800: '#284745',
  900: '#202020',
};

export const blue = withAlphas({
  lightest: '#F5F8FF',
  light: '#EBEFFF',
  main: '#2970FF',
  dark: '#004EEB',
  darkest: '#00359E',
  contrastText: '#FFFFFF',
});

export const green = withAlphas({
  lightest: '#8CA9A9', // jaśniejszy odcień
  light: '#4D7D7D', // nieco jaśniejszy odcień
  main: '#085454', // główny kolor
  dark: '#064040', // nieco ciemniejszy odcień
  darkest: '#042B2B', // ciemniejszy odcień
  contrastText: '#FFFFFF',
});

export const indigo = withAlphas({
  lightest: '#B2F4D8', // jaśniejszy odcień
  light: '#63E6B7', // nieco jaśniejszy odcień
  main: '#14D990', // główny kolor
  dark: '#0FAA6E', // nieco ciemniejszy odcień
  darkest: '#09724A', // ciemniejszy odcień
  contrastText: '#FFFFFF',
});

export const purple = withAlphas({
  lightest: '#A89ABD', // jaśniejszy odcień
  light: '#7C6FA6', // nieco jaśniejszy odcień
  main: '#51278C', // główny kolor
  dark: '#3E1D70', // nieco ciemniejszy odcień
  darkest: '#2C124F', // ciemniejszy odcień
  contrastText: '#FFFFFF',
});

export const gold = withAlphas({
  lightest: '#FFEEA1', // jaśniejszy odcień
  light: '#FFEA66', // nieco jaśniejszy odcień
  main: '#FFDD33', // główny kolor
  dark: '#E6C200', // nieco ciemniejszy odcień
  darkest: '#B39600', // ciemniejszy odcień
  contrastText: '#085454',
});

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF',
});

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF',
});

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF',
});

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF',
});

export const dark = withAlphas({
  lightest: '#4C4C4C',
  light: '#363636',
  main: '#202020',
  dark: '#1c1c1c',
  darkest: '#191919',
  contrastText: '#FFFFFF',
});
