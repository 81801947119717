import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

import ENDPOINTS from '@constants/endpoints';

import useWebSocket from '@hooks/use-websocket-tenant-status';

import { NGlobal } from '@typings/global';
import { NRedux } from '@typings/redux';

const WebSocketComponent = () => {
  const globalState = useSelector((state: NRedux.IState) => state.global.tenantState);
  const _tenant = globalState
    ? globalState?.filter((item: NGlobal.TObject) => item.schema_name !== 'public')
    : [];
  const schemaName = _tenant?.[0]?.schema_name;
  useWebSocket(schemaName);

  return null;
};
export default WebSocketComponent;
