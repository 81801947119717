import { ReactNode, createContext, useContext, useMemo } from 'react';

import Web3 from 'web3';

type Web3Instance = Web3 | null;

const Web3Context = createContext<Web3Instance>(null);

export const useWeb3 = (): Web3Instance => {
  return useContext(Web3Context);
};

interface Web3ProviderProps {
  children: ReactNode;
}

export const Web3Provider: React.FC<Web3ProviderProps> = ({ children }) => {
  const web3 = useMemo(() => {
    if (typeof window !== 'undefined' && (window as any).ethereum) {
      return new Web3((window as any).ethereum);
    }
    return new Web3();
  }, []);

  return <Web3Context.Provider value={web3}>{children}</Web3Context.Provider>;
};
