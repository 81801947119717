import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { updateTenantStatus } from '@redux/actions/globalActions';

const useWebSocket = (tenant_name: string) => {
  const dispatch = useDispatch();
  const prevTenantNameRef = useRef<string | undefined>();

  useEffect(() => {
    if (!tenant_name || tenant_name === prevTenantNameRef.current) {
      return;
    }

    prevTenantNameRef.current = tenant_name;
    const wsAdrr = process.env.NEXT_PUBLIC_WS_SOCKET_PAGE || 'wss://moneya.org';
    const ws = new WebSocket(`${wsAdrr}/ws/updates/${tenant_name}/`);

    ws.onopen = () => {
      console.log('WebSocket connection opened');
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        const [schemaName, status] = data.message.split(':');
        if (schemaName === tenant_name) {
          dispatch(updateTenantStatus(tenant_name, status));
        }
      } catch (error) {
        console.error('Error handling WebSocket message:', error);
      }
    };

    ws.onclose = (event) => {
      console.log(`Disconnected from websocket with code ${event.code} and reason ${event.reason}`);
    };

    return () => {
      ws.close();
    };
  }, [tenant_name, dispatch]);
};

export default useWebSocket;
