import { combineReducers } from 'redux';

import globalReducer from './globalReducer';
import sessionReducer from './session';

// import sessionReducer from './session';

const reducers = {
  global: globalReducer,
  session: sessionReducer,
};

export default combineReducers(reducers);
