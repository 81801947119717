export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  vision: '/vision',
  payment_method: '/payment-methods',
  developer: '/developer',
  about_us: '/about-us',
  terms: '/terms',
  privacy: '/privacy',
  register: '/register',
  login: '/login',
  restore_password: '/restore-password',
  succes_create_account: '/succes-new-account',
  dashboard: {
    index: '/app/',
    invoice: '/invoice/',
    account: '/app/accounts/',
    settings: '/app/settings/',
    checkouts: {
      index: '/app/checkouts/',
      details: '/app/checkouts/:customerId:/',
      edit: '/app/checkouts/:customerId:/edit',
      create: '/app/checkouts/add',
    },
    customers: {
      index: '/app/customers/',
      details: '/app/customers/:customerId:/',
      edit: '/app/customers/:customerId:/edit',
      create: '/app/customers/add',
    },
    products: {
      index: '/app/products/',
      details: '/app/products/:customerId:/',
      edit: '/app/products/:customerId:/edit',
      create: '/app/products/add',
    },
    payments: {
      index: '/app/payments/',
      details: '/app/payments/:customerId:/',
      edit: '/app/payments/:customerId:/edit',
    },
  },
  401: '/401',
  404: '/404',
  500: '/500',
};
