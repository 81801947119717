import { SET_TENANT, UPDATE_TENANT_STATUS } from '@constants/redux';

import { NGlobal } from '@typings/global';
import { NRedux } from '@typings/redux';

export const setGlobalTenant = (tenantObject: NGlobal.TObject): NRedux.TActionObject => {
  return {
    type: SET_TENANT,
    payload: tenantObject,
  };
};

export const updateTenantStatus = (schemaName: string, status: string): NRedux.TActionObject => {
  return {
    type: UPDATE_TENANT_STATUS,
    payload: { schemaName, status },
  };
};
