import { useTheme } from '@mui/material/styles';

export function Logomark(props: any) {
  return (
    <svg viewBox="0 0 40 80" aria-hidden="true" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20ZM4 20c0 7.264 5.163 13.321 12.02 14.704C17.642 35.03 19 33.657 19 32V8c0-1.657-1.357-3.031-2.98-2.704C9.162 6.68 4 12.736 4 20Z"
      />
    </svg>
  );
}

export function Logo({ inverse = false }) {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;
  const mainColor =
    inverse || theme.palette.mode === 'dark'
      ? theme.palette.neutral[50]
      : theme.palette.neutral[900];

  return (
    <svg
      width="131"
      height="26"
      viewBox="0 0 131 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_10)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8801 25.6398H15.5751C14.6489 25.6398 13.8918 24.8827 13.8918 23.9566V17.7261C13.8918 13.9684 11.6715 11.7457 7.9114 11.7457H1.68323C0.757105 11.7457 0 10.9886 0 10.0625V1.76208C0 0.840593 0.761736 0.0788574 1.68323 0.0788574H9.98823C10.9144 0.0788574 11.6715 0.835962 11.6715 1.76208V5.91574V7.99257C11.6715 11.7503 13.8918 13.973 17.6519 13.973H23.8801C24.8062 13.973 25.5633 14.7301 25.5633 15.6562V23.9612C25.5633 24.8804 24.8016 25.6445 23.8801 25.6445V25.6398Z"
          fill="#14D990"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83457 13.9707C2.61398 13.9707 0 16.5824 0 19.8053C0 23.0282 2.61167 25.6398 5.83457 25.6398C9.05748 25.6398 11.6691 23.0258 11.6691 19.8053C11.6691 16.5847 9.05516 13.9707 5.83457 13.9707Z"
          fill="#085454"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7264 0.0788574C16.5035 0.0788574 13.8918 2.69284 13.8918 5.91343C13.8918 9.13402 16.5058 11.748 19.7264 11.748C22.947 11.748 25.561 9.13402 25.561 5.91343C25.561 2.69284 22.947 0.0788574 19.7264 0.0788574Z"
          fill="#FFDD33"
        />
      </g>
      <path
        d="M36.7441 20.6149V7.21496H39.8485V12.9857H39.6285C39.6285 11.6164 39.7996 10.4753 40.1418 9.5624C40.484 8.64951 40.9973 7.96484 41.6818 7.50839C42.3662 7.03565 43.2054 6.79927 44.1995 6.79927H44.3706C45.3809 6.79927 46.2202 7.03565 46.8883 7.50839C47.5727 7.96484 48.086 8.64951 48.4282 9.5624C48.7867 10.4753 48.966 11.6164 48.966 12.9857H48.0127C48.0127 11.6164 48.1838 10.4753 48.526 9.5624C48.8845 8.64951 49.406 7.96484 50.0904 7.50839C50.7748 7.03565 51.6141 6.79927 52.6081 6.79927H52.7792C53.7896 6.79927 54.6369 7.03565 55.3214 7.50839C56.0221 7.96484 56.5517 8.64951 56.9102 9.5624C57.2687 10.4753 57.448 11.6164 57.448 12.9857V20.6149H53.537V12.7657C53.537 12.0321 53.3414 11.4371 52.9503 10.9806C52.5755 10.5242 52.0378 10.296 51.337 10.296C50.6363 10.296 50.0823 10.5323 49.6749 11.0051C49.2675 11.4615 49.0638 12.0729 49.0638 12.839V20.6149H45.1283V12.7657C45.1283 12.0321 44.9328 11.4371 44.5417 10.9806C44.1669 10.5242 43.6373 10.296 42.9528 10.296C42.2358 10.296 41.6736 10.5323 41.2662 11.0051C40.8588 11.4615 40.6551 12.0729 40.6551 12.839V20.6149H36.7441Z"
        fill={mainColor}
      />
      <path
        d="M66.8196 21.0795C65.6463 21.0795 64.6034 20.9002 63.6909 20.5416C62.7783 20.1666 62.0042 19.6613 61.3687 19.0255C60.7495 18.3897 60.2769 17.6643 59.951 16.8492C59.625 16.0178 59.4621 15.1457 59.4621 14.2328V13.6704C59.4621 12.7412 59.625 11.8609 59.951 11.0295C60.2932 10.1819 60.782 9.44014 61.4176 8.80437C62.0694 8.16861 62.8516 7.67141 63.7642 7.31277C64.6767 6.93784 65.6952 6.75037 66.8196 6.75037C67.9766 6.75037 69.0033 6.93784 69.8996 7.31277C70.8121 7.67141 71.5862 8.16861 72.2217 8.80437C72.8572 9.44014 73.3461 10.1819 73.6883 11.0295C74.0305 11.8609 74.2016 12.7412 74.2016 13.6704V14.2328C74.2016 15.1457 74.0387 16.0178 73.7128 16.8492C73.3869 17.6643 72.9061 18.3897 72.2706 19.0255C71.6514 19.6613 70.8855 20.1666 69.9729 20.5416C69.0603 20.9002 68.0092 21.0795 66.8196 21.0795ZM66.8196 17.7784C67.5855 17.7784 68.2211 17.6154 68.7263 17.2894C69.2314 16.9633 69.6144 16.5069 69.8751 15.92C70.1521 15.3332 70.2907 14.6811 70.2907 13.9638C70.2907 13.1977 70.1521 12.5293 69.8751 11.9587C69.5981 11.3719 69.1988 10.9073 68.6774 10.565C68.1722 10.2226 67.553 10.0514 66.8196 10.0514C66.1026 10.0514 65.4834 10.2226 64.9619 10.565C64.4405 10.9073 64.0412 11.3719 63.7642 11.9587C63.5034 12.5293 63.3731 13.1977 63.3731 13.9638C63.3731 14.6811 63.5034 15.3332 63.7642 15.92C64.0249 16.5069 64.416 16.9633 64.9375 17.2894C65.4589 17.6154 66.0863 17.7784 66.8196 17.7784Z"
        fill={mainColor}
      />
      <path
        d="M76.4423 20.6149V7.21496H79.5466V12.9613H79.3266C79.3266 11.592 79.4977 10.4508 79.8399 9.53795C80.1984 8.62506 80.7362 7.94039 81.4532 7.48394C82.1702 7.02749 83.0421 6.79927 84.0687 6.79927H84.2398C85.8042 6.79927 86.9938 7.31277 87.8086 8.33978C88.6397 9.35048 89.0552 10.891 89.0552 12.9613V20.6149H85.1442V12.7412C85.1442 12.0239 84.9324 11.4371 84.5087 10.9806C84.085 10.5242 83.5146 10.296 82.7976 10.296C82.0643 10.296 81.4695 10.5323 81.0132 11.0051C80.5732 11.4615 80.3533 12.0647 80.3533 12.8146V20.6149H76.4423Z"
        fill={mainColor}
      />
      <path
        d="M98.0345 21.0795C96.8938 21.0795 95.8834 20.8839 95.0034 20.4926C94.1398 20.1014 93.4146 19.5798 92.8279 18.9277C92.2576 18.2593 91.8176 17.5176 91.508 16.7025C91.2147 15.8711 91.068 15.0234 91.068 14.1595V13.6704C91.068 12.7738 91.2147 11.918 91.508 11.1029C91.8176 10.2715 92.2576 9.5298 92.8279 8.87773C93.3983 8.22566 94.1072 7.71216 94.9545 7.33722C95.8182 6.94599 96.796 6.75037 97.8878 6.75037C99.3218 6.75037 100.528 7.0764 101.505 7.72847C102.5 8.36423 103.257 9.20376 103.779 10.2471C104.3 11.2741 104.561 12.3989 104.561 13.6215V14.9419H92.7057V12.7168H102.19L100.919 13.7438C100.919 12.945 100.805 12.2603 100.577 11.6898C100.348 11.1192 100.006 10.6872 99.55 10.3938C99.11 10.0841 98.5559 9.92919 97.8878 9.92919C97.2034 9.92919 96.6249 10.0841 96.1523 10.3938C95.6797 10.7035 95.3212 11.16 95.0768 11.7631C94.8323 12.35 94.7101 13.0754 94.7101 13.9394C94.7101 14.7382 94.8242 15.4391 95.0523 16.0423C95.2805 16.6292 95.639 17.0856 96.1278 17.4116C96.6167 17.7377 97.2523 17.9007 98.0345 17.9007C98.7515 17.9007 99.3381 17.7621 99.7944 17.485C100.251 17.2079 100.56 16.8655 100.723 16.458H104.316C104.121 17.3546 103.738 18.1534 103.168 18.8543C102.597 19.5553 101.88 20.1014 101.017 20.4926C100.153 20.8839 99.1589 21.0795 98.0345 21.0795Z"
        fill={mainColor}
      />
      <path
        d="M106.398 25.9211V22.5956H109.184C109.608 22.5956 109.966 22.5385 110.26 22.4244C110.553 22.3266 110.789 22.1554 110.969 21.9109C111.148 21.6664 111.295 21.3322 111.409 20.9083L114.709 7.21496H118.424L114.684 21.6908C114.423 22.7341 114.057 23.5655 113.584 24.185C113.128 24.8044 112.509 25.2446 111.726 25.5054C110.96 25.7825 109.983 25.9211 108.793 25.9211H106.398ZM110.602 20.3215V17.2649H113.437V20.3215H110.602ZM108.989 20.3215L104.882 7.21496H108.818L112.704 20.3215H108.989Z"
        fill={mainColor}
      />
      <path
        d="M127.896 20.6149V16.6536H127.236V12.35C127.236 11.6653 127.073 11.1518 126.747 10.8095C126.421 10.4671 125.9 10.296 125.183 10.296C124.824 10.296 124.352 10.3041 123.765 10.3204C123.178 10.3367 122.575 10.3612 121.956 10.3938C121.337 10.4264 120.775 10.459 120.269 10.4916V7.19051C120.644 7.15791 121.084 7.1253 121.589 7.0927C122.095 7.0601 122.616 7.03565 123.154 7.01934C123.692 7.00304 124.197 6.99489 124.669 6.99489C126.038 6.99489 127.187 7.19051 128.116 7.58175C129.061 7.97299 129.778 8.568 130.267 9.36678C130.756 10.1493 131 11.16 131 12.3989V20.6149H127.896ZM123.618 20.9572C122.657 20.9572 121.809 20.7861 121.076 20.4437C120.359 20.1014 119.797 19.6124 119.389 18.9766C118.982 18.3245 118.778 17.5502 118.778 16.6536C118.778 15.6755 119.031 14.8767 119.536 14.2573C120.041 13.6215 120.75 13.1569 121.663 12.8635C122.575 12.5538 123.626 12.3989 124.816 12.3989H127.676V14.5752H124.792C124.107 14.5752 123.577 14.7463 123.203 15.0887C122.844 15.4147 122.665 15.863 122.665 16.4335C122.665 16.9715 122.844 17.4116 123.203 17.754C123.577 18.08 124.107 18.243 124.792 18.243C125.232 18.243 125.623 18.1697 125.965 18.023C126.323 17.8599 126.617 17.591 126.845 17.216C127.073 16.8411 127.203 16.3194 127.236 15.6511L128.165 16.6292C128.083 17.5584 127.855 18.3408 127.48 18.9766C127.122 19.6124 126.617 20.1014 125.965 20.4437C125.329 20.7861 124.547 20.9572 123.618 20.9572Z"
        fill={mainColor}
      />
      <defs>
        <clipPath id="clip0_1_10">
          <rect width="25.561" height="25.561" fill="white" transform="translate(0 0.0788574)" />
        </clipPath>
      </defs>
    </svg>
  );
}
