import { useEffect, useMemo, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SessionProvider } from 'next-auth/react';
import { NextIntlClientProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { gtmConfig } from 'src/config';

import * as gtag from '@libs/gtag';

import { useStore } from '@redux/store';

import WebSocketComponent from '@components/WebSocketComponent';
import { SplashScreen } from '@components/splash-screen';
import { Toaster } from '@components/toaster';

import BasketContext from '@contexts/basket-context';
import { SettingsConsumer, SettingsProvider } from '@contexts/settings-context';
import { Web3Provider } from '@contexts/web3Context';
import { WebSocketProvider } from '@contexts/websocket-context';

import { useAnalytics } from '@hooks/use-analytics';

import { NGlobal } from '@typings/global';

import configureAxios from '@utils/configureAxios';

import { createTheme } from '@theme/index';

const staleTime = 5 * 60 * 1000; //5 min
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime,
    },
  },
});
interface Billing {
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  paymentMethod: string;
  state: string;
  zip: string;
}
console.log(
  '%c\n\n' +
    "##::::'##::'#######::'##::: ##:'########:'##:::'##::::'###::::\n" +
    "###::'###:'##.... ##: ###:: ##: ##.....::. ##:'##::::'## ##:::\n" +
    "####'####: ##:::: ##: ####: ##: ##::::::::. ####::::'##:. ##::\n" +
    "## ### ##: ##:::: ##: ## ## ##: ######:::::. ##::::'##:::. ##:\n" +
    '##. #: ##: ##:::: ##: ##. ####: ##...::::::: ##:::: #########:\n' +
    '##:.:: ##: ##:::: ##: ##:. ###: ##:::::::::: ##:::: ##.... ##:\n' +
    '##:::: ##:. #######:: ##::. ##: ########:::: ##:::: ##:::: ##:\n' +
    '..:::::..:::.......:::..::::..::........:::::..:::::..:::::..::\n' +
    'Join Us!\n',
  'font-family:monospace;color:#14D990;font-size:12px;'
);

const App = (props: AppProps) => {
  const {
    Component,
    pageProps: {
      session,
      // emotionCache = clientSideEmotionCache,
      dehydratedState,
      messages,
      ...pageProps
    },
  } = props;

  const store = useStore(pageProps.initialReduxState as any);
  const getLayout = Component.getLayout ?? ((page: any) => page);

  useEffect(() => {
    hotjar.initialize(3604019, 6);
  }, []);
  interface Product {
    id: string;
    image: string;
    name: string;
    price: number;
    quantity: number;
    currency: NGlobal.TObject;
  }
  interface Basket {
    products: Product[];
    total_price: number;
    isCrypto?: boolean;
    totalFromCrypto?: number;
  }
  useAnalytics(gtmConfig);
  configureAxios();
  const [basket, setBasket] = useState<Basket>({
    products: [],
    total_price: 0,
    totalFromCrypto: 0,
    isCrypto: false,
  });
  const initialBilling: Billing = {
    address: '',
    email: '',
    firstName: '',
    lastName: '',
    city: '',
    paymentMethod: 'visa',
    state: '',
    zip: '',
  };
  const [basketData, setBasketData] = useState({});
  const [billing, setBilling] = useState(initialBilling);

  const contextValue = useMemo(
    () => ({
      basket: {
        ...basket,
        isCrypto: basket?.isCrypto || false,
        totalFromCrypto: basket?.totalFromCrypto || 0,
      },
      setBasket,
      billing,
      setBilling,
      basketData,
      setBasketData,
    }),
    [basket, setBasket, billing, setBilling, basketData, setBasketData]
  );

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />

      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gtag.GA_TRACKING_ID}');
        `}
      </Script>
      {/* Temp off */}
      {/* <Script id="cookieTag-init" strategy="afterInteractive">
        {`
        var cookie3Options = {"siteId":308,"additionalTracking":true,"cookielessEnabled":true};
        window._paq = window._paq || [];
        (function () {
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = 'https://cdn.cookie3.co/scripts/analytics/latest/cookie3.analytics.min.js'; s.parentNode.insertBefore(g, s);
        })();
      `}
      </Script> */}

      <QueryClientProvider client={queryClient}>
        <NextIntlClientProvider locale={router.locale} messages={messages}>
          {/* <Hydrate state={dehydratedState}> */}
          <SettingsProvider>
            <SettingsConsumer>
              {(settings) => {
                // Prevent theme flicker when restoring custom settings from browser storage
                // if (!settings.isInitialized) {
                //   return null;
                // }
                const theme = createTheme({
                  colorPreset: settings.colorPreset,
                  contrast: settings.contrast,
                  direction: settings.direction,
                  paletteMode: settings.paletteMode,
                  responsiveFontSizes: settings.responsiveFontSizes,
                });

                // Prevent guards from redirecting
                const showSlashScreen = false;

                return (
                  <ThemeProvider theme={theme}>
                    <Head>
                      <meta name="theme-color" content="#081A1A" />
                      <meta name="apple-mobile-web-app-capable" content="yes" />
                      <meta name="color-scheme" content={settings.paletteMode} />
                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                      <meta name="theme-color" content={theme.palette.neutral[900]} />
                    </Head>
                    <CssBaseline />
                    {showSlashScreen ? (
                      <SplashScreen />
                    ) : (
                      <ReduxProvider store={store}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Web3Provider>
                            <WebSocketProvider>
                              <BasketContext.Provider value={contextValue}>
                                <SessionProvider session={session}>
                                  {getLayout(<Component {...pageProps} />)}
                                </SessionProvider>
                                <WebSocketComponent />
                              </BasketContext.Provider>
                            </WebSocketProvider>
                          </Web3Provider>
                        </LocalizationProvider>
                      </ReduxProvider>
                    )}
                    <Toaster />
                  </ThemeProvider>
                );
              }}
            </SettingsConsumer>
          </SettingsProvider>
          {/* </Hydrate> */}
          <ReactQueryDevtools />
        </NextIntlClientProvider>
      </QueryClientProvider>
    </>
  );
};
export default App;
