import { toast } from 'react-toastify';

import axios from 'axios';
import cookie from 'js-cookie';
import { getSession } from 'next-auth/react';

import { STORAGE_ACCESS_TOKEN, STORAGE_TENANT_NAME } from '@constants/storageKeys';

import { paths } from '@paths/index';

import parseResponseError from '@utils/parseResponseError';
import { refreshToken } from '@utils/refreshToken';
import { removeAuthCookies } from '@utils/removeAuthCookies';

const link = process.env.API_URL || process.env.NEXT_PUBLIC_API_URL;

export const handleError = async (error: any): Promise<any> => {
  if (error.response && error.response.data) {
    if (error.response.status === 429) {
      toast.error(parseResponseError(error.response.data.message));
    }

    // Obsługa przeterminowanego tokena.
    if (error.response.status === 401) {
      const session = await getSession();
      const rToken: string | undefined = session?.user?.refreshToken as string;
      try {
        if (!rToken || rToken?.includes('null')) throw Error('Błędny token');

        const request = error.config;

        if (!request._retry) {
          request._retry = true;
          request._omitSettingAuthorizationHeader = true;
          const { data } = await refreshToken(null, rToken);

          try {
            request.headers.Authorization = `Bearer ${data.access}`;
            cookie.set(STORAGE_ACCESS_TOKEN, data.access);

            const retry = await axios(request);
            return Promise.resolve(retry);
          } catch (err) {
            return Promise.reject(err);
          }
        } else {
          throw error;
        }
      } catch (err) {
        const { pathname } = window.location;
        removeAuthCookies();
        if (!(pathname === paths.login || pathname === paths.dashboard.index))
          window.location.href = '/';
      }
    }
  }

  return Promise.reject(error);
};

const configureAxios = async (schemaName?: string): Promise<void> => {
  const accesToken = cookie.get(STORAGE_ACCESS_TOKEN);

  axios.defaults.baseURL = link;
  if (accesToken) {
    axios.defaults.headers['Authorization'] = `Bearer ${accesToken}`;
  }

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const response = await handleError(error);
      return response;
    }
  );

  axios.interceptors.request.use(async (config: any): Promise<any> => {
    let finalSchemaName = schemaName;

    if (schemaName) {
      const currentSchemaName = cookie.get(STORAGE_TENANT_NAME);

      if (!currentSchemaName || currentSchemaName !== schemaName) {
        cookie.set(STORAGE_TENANT_NAME, schemaName);
      }
      finalSchemaName = schemaName;
    } else {
      finalSchemaName = cookie.get(STORAGE_TENANT_NAME);
    }
    if (finalSchemaName) {
      config.headers['Tenant-Name'] = finalSchemaName;
    }

    // config.cancelToken = new CancelToken((cancelParam) => {
    //   // cancel = cancelParam;
    // });

    // if (typeof window !== 'undefined')
    //   if (navigator && !navigator?.onLine) {
    //   // const methodToSave = ['post', 'patch', 'put', 'delete'];

    //   // if (config?.method && methodToSave.includes(config.method)) {
    //   //   const axiosRequests: any = window.localStorage.getItem(STORAGE_AXIOS_REQUESTS);
    //   //   let requests = JSON.parse(axiosRequests) || [];
    //   //   requests = [...requests, JSON.stringify(config)];
    //   //   window.localStorage.setItem(STORAGE_AXIOS_REQUESTS, JSON.stringify(requests));

    //   //   cancel();
    //   //   return;
    //   // }
    // }

    return config;
  });
};

export default configureAxios;
