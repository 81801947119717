import { ReactNode, createContext, useContext, useState } from 'react';

import { NGlobal } from '@typings/global';

const defaultWebSocketFunction: (url: string) => WebSocket | null = () => null;

const WebSocketContext = createContext<(url: string) => WebSocket | null>(defaultWebSocketFunction);

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const [sockets, setSockets] = useState<NGlobal.TObject>({});

  const getWebSocket = (url: string): WebSocket => {
    if (!sockets[url]) {
      const newWs = new WebSocket(url);
      setSockets((prevSockets) => ({
        ...prevSockets,
        [url]: newWs,
      }));
      return newWs;
    }
    return sockets[url];
  };

  return <WebSocketContext.Provider value={getWebSocket}>{children}</WebSocketContext.Provider>;
};
