import { CookieSerializeOptions, serialize } from 'cookie';
import { NextApiResponse } from 'next';

export const setCookie = (
  res: NextApiResponse,
  name: string,
  value: unknown,
  options: CookieSerializeOptions = {}
): void => {
  const stringValue = typeof value === 'object' ? 'j:' + JSON.stringify(value) : String(value);

  if (options?.maxAge) {
    options.expires = new Date(Date.now() + options.maxAge);
    options.maxAge /= 1000;
  }

  options.sameSite = 'strict';

  /* istanbul ignore else */
  if (res) res.setHeader('Set-Cookie', serialize(name, String(stringValue), options));
};
