import { CURRENT_TENANT, SET_TENANT, UPDATE_TENANT_STATUS } from '@constants/redux';

import { NRedux } from '@typings/redux';

const initialGlobalState: NRedux.TGlobalState = {
  tenantState: [],
  currentTenant: {},
};

const mapReducer = (state = initialGlobalState, { type, payload }: NRedux.TActionObject): any => {
  switch (type) {
    case SET_TENANT:
      return {
        ...state,
        tenantState: payload,
      };
    case UPDATE_TENANT_STATUS: {
      const updatedTenantState = state.tenantState.map((tenant: any) =>
        tenant.schema_name === payload?.schemaName ? { ...tenant, status: payload.status } : tenant
      );
      return {
        ...state,
        tenantState: updatedTenantState,
      };
    }
    case CURRENT_TENANT:
      return {
        ...state,
        currentTenant: payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
