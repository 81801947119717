import { createContext } from 'react';

import { NContext } from '@typings/contexts';

const BasketContext = createContext<NContext.TBasketContext>({
  basket: {
    products: [],
    total_price: 0,
    isCrypto: false,
    totalFromCrypto: 0,
  },
  setBasket: () => null,
  billing: {
    address: '',
    email: '',
    firstName: '',
    lastName: '',
    city: '',
    paymentMethod: 'visa',
    crypto_wallet_address: '',
    state: '',
    zip: '',
    phone: '',
  },
  setBilling: () => null,
  basketData: {},
  setBasketData: () => null,
});

BasketContext.displayName = 'BasketContext';

export default BasketContext;
