import axios from 'axios';

import ENDPOINTS from '@constants/endpoints';
import { STORAGE_ACCESS_TOKEN, STORAGE_REFRESH_TOKEN } from '@constants/storageKeys';

import { paths } from '@paths/index';

import { removeAuthCookies } from '@utils/removeAuthCookies';
import { setCookie } from '@utils/setCookie';

const redirect = (res: any): void => {
  if (res) {
    res.statusCode = 302;
    setCookie(res, STORAGE_ACCESS_TOKEN, '', { maxAge: 0 });
    setCookie(res, STORAGE_REFRESH_TOKEN, '', { maxAge: 0 });
    res.setHeader('Location', paths.login);
  }
};

export const refreshToken = async (
  res: any,
  rToken: string | undefined,
  removeAuth = true
): Promise<any> => {
  const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
  });

  let data;

  if (!rToken) {
    redirect(res);
    return;
  }

  try {
    data = await axiosInstance.post(ENDPOINTS.REFRESH, {
      refresh: rToken,
    });
    setCookie(res, STORAGE_ACCESS_TOKEN, data.data.access, { maxAge: 0 });
  } catch (err) {
    if (removeAuth) {
      removeAuthCookies(res);
      redirect(res);
    }
    return null;
  }

  return data;
};
