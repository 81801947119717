import cookie from 'js-cookie';

import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_REFRESH_TOKEN,
  STORAGE_TENANT_NAME,
} from '@constants/storageKeys';

import { setCookie } from '@utils/setCookie';

export const removeAuthCookies = (res?: any): void => {
  if (res) {
    setCookie(res, STORAGE_ACCESS_TOKEN, null);
    setCookie(res, STORAGE_REFRESH_TOKEN, null);
    setCookie(res, STORAGE_TENANT_NAME, null);
  } else {
    cookie.remove(STORAGE_ACCESS_TOKEN);
    cookie.remove(STORAGE_REFRESH_TOKEN);
    cookie.remove(STORAGE_TENANT_NAME);
  }
};
