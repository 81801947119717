import { NGlobal } from '@typings/global';

export const SET_SESSION = 'SET_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';

export const setSession = (session: NGlobal.TObject) => ({
  type: SET_SESSION,
  payload: session,
});

export const clearSession = () => ({
  type: CLEAR_SESSION,
});
