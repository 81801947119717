import { useMemo } from 'react';

import { Store } from 'redux';

import { configureStore } from '@reduxjs/toolkit';

import { NRedux } from '@typings/redux';

import reducers from './reducers';

let store: any;

function initStore(preloadedState: any): any {
  return configureStore({
    reducer: reducers,
    preloadedState,
    // devTools: composeWithDevTools(applyMiddleware(thunkMiddleware, logger)),
  });
}

export const initializeStore = (preloadedState: NRedux.IState): any => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState: NRedux.IState): Store {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
